import { logout as authLogout } from "@/_services/authentication"
import {
  getAuthorizationToken,
  getUserCurrentContextIndex,
  resetHeaderAuthorization,
  setHeaderAuthorization,
  setHeaderUserExternalContexts,
} from "@/_services/axios"
import { getHostConfig } from "@/_services/theming"
import { getUser, preloadUserConfiguration, unloadUserConfiguration } from "@/_services/userConfiguration"
import { debug, httpHeaders, localStorageKeys, searchParamToObject } from "@/_services/utils"
import axios from "axios"
import { objectToSearchParam } from "basikon-common-utils"

// best practice is ~2 min for tokens having a lifetime < 15 min and ~4 min for longer ones
const refreshTokenLifetimeThreshold = 900 // expressed in seconds, here 15 min
const refreshTokenLongThreshold = 240
const refreshTokenShortThreshold = 120

const state = {
  loginError: null,
  refreshTokenIat: null,
  refreshTokenExpiresIn: null,
  isRefreshingToken: false,
}

async function redirectToProviderLoginPage() {
  const queryParams = searchParamToObject(window.location.search)
  const hostConfig = getHostConfig()
  const oidc = queryParams.oidc || hostConfig.oidc
  if (!oidc) return

  try {
    // make sure to sync this list with what is returned by the server
    // to avoid passing over the network sensitive information
    const {
      oidcId,
      authorizationUrl,
      logoutUrl,
      scope,
      state,
      nonce,
      clientId,
      connection,
      redirectUri,
      responseMode,
      responseType,
      codeChallenge,
      codeChallengeMethod,
      codeVerifier,
      acrValues,
    } = (await axios.get(`/api/core/oidc/${oidc}`)).data

    const queryParams = objectToSearchParam({
      scope,
      state,
      nonce,
      client_id: clientId,
      connection,
      redirect_uri: redirectUri,
      response_mode: responseMode,
      response_type: responseType,
      code_challenge: codeChallenge,
      code_challenge_method: codeChallengeMethod,
      acr_values: acrValues,
    })

    localStorage.setItem(localStorageKeys.OIDC.ID, oidcId)
    localStorage.setItem(localStorageKeys.OIDC.LOGOUT_URL, logoutUrl)
    localStorage.setItem(localStorageKeys.OIDC.REDIRECT_URI, redirectUri)

    if (responseMode !== "form_post") {
      localStorage.setItem(localStorageKeys.OIDC.STATE, state)
      localStorage.setItem(localStorageKeys.OIDC.NONCE, nonce)
      localStorage.setItem(localStorageKeys.OIDC.CODE_VERIFIER, codeVerifier)

      const searchParam = searchParamToObject(window.location.search)
      searchParam.oidc = undefined
      localStorage.setItem(localStorageKeys.OIDC.ORIGINAL_URL, window.location.origin + window.location.pathname + objectToSearchParam(searchParam))
    }

    window.location.href = `${authorizationUrl}${queryParams}`
    return { isRedirectingToOidcProvider: true }
  } catch (error) {
    console.error("Error getting OIDC configuration", error)
  }
}

/**
 * This method must return a value (any will do) to indicate succesful login,
 * otherwise when using the automatic OIDC flow an infinite loop will happen.
 */
async function checkAuthentication({ history }) {
  const hostConfig = getHostConfig()
  const oidcOriginalUrl = localStorage.getItem(localStorageKeys.OIDC.ORIGINAL_URL)
  const redirect = oidcOriginalUrl || new URLSearchParams(window.location.search).get("redirect") || ""
  const queryParams = searchParamToObject(window.location.search)
  const oidc = queryParams.oidc || hostConfig.oidc
  const accessToken = queryParams.access_token || queryParams.accessToken
  let { code, state } = queryParams

  if (accessToken && oidc) {
    const { oidcId } = (await axios.get(`/api/core/oidc/${oidc}`)).data
    const headers = (await axios.post(`/api/core/oidc/${oidcId}/login`, { accessToken })).headers
    const authorization = headers.authorization

    if (authorization) {
      setHeaderAuthorization(authorization)
      if (headers[httpHeaders.userContexts] && headers[httpHeaders.contextToken]) {
        setHeaderUserExternalContexts(headers[httpHeaders.userContexts], headers[httpHeaders.contextToken])
      }

      const userCurrentContextIndex = getUserCurrentContextIndex()
      if (userCurrentContextIndex !== undefined && userCurrentContextIndex !== null && userCurrentContextIndex > -1) {
        const options = {
          headers: {
            [httpHeaders.contextToken]: headers[httpHeaders.contextToken],
          },
        }

        await axios.get(`/api/core/user-switch-context?contextIndex=${userCurrentContextIndex}`, options)
      }

      return { isAuthenticated: true }
    }
  }

  if (!code || !state) {
    // Check also in hash because sometimes the "?" is transformed to "#"
    if (window.location.hash) {
      const hashParams = searchParamToObject(window.location.hash.replace("#", ""))
      if (hashParams.code) code = hashParams.code
      if (hashParams.state) state = hashParams.state
    }

    if (!code || !state) return { shouldRedirectToOidcProvider: true }
  }

  const localState = localStorage.getItem(localStorageKeys.OIDC.STATE)
  if (state !== localState) {
    console.error("Cannot authenticate user with OIDC")
    return { shouldRedirectToOidcProvider: true }
  }

  const oidcId = localStorage.getItem(localStorageKeys.OIDC.ID)
  const { headers, data } = await axios.post(`/api/core/oidc/${oidcId}/login`, {
    code,
    state: localState,
    nonce: localStorage.getItem(localStorageKeys.OIDC.NONCE),
    codeVerifier: localStorage.getItem(localStorageKeys.OIDC.CODE_VERIFIER),
  })

  const { idToken } = data || {}

  setHeaderAuthorization(`Bearer ${headers.authorization?.substring(7)}`)
  if (headers[httpHeaders.userContexts] && headers[httpHeaders.contextToken]) {
    setHeaderUserExternalContexts(headers[httpHeaders.userContexts], headers[httpHeaders.contextToken])
  }
  const userCurrentContextIndex = getUserCurrentContextIndex()
  if (userCurrentContextIndex !== undefined && userCurrentContextIndex !== null && userCurrentContextIndex > -1) {
    const options = {
      headers: {
        [httpHeaders.contextToken]: headers[httpHeaders.contextToken],
      },
    }

    await axios.get(`/api/core/user-switch-context?contextIndex=${userCurrentContextIndex}`, options)
  }

  localStorage.setItem(localStorageKeys.OIDC.ID_TOKEN, idToken)
  localStorage.removeItem(localStorageKeys.OIDC.NONCE)
  localStorage.removeItem(localStorageKeys.OIDC.STATE)
  localStorage.removeItem(localStorageKeys.OIDC.CODE_VERIFIER)
  localStorage.removeItem(localStorageKeys.OIDC.ORIGINAL_URL)

  unloadUserConfiguration()

  if (redirect) {
    // redirect is a full URL, we only need the part after the hostname
    let _redirect = redirect.substring(redirect.indexOf("//") + 2)
    _redirect = _redirect.substring(_redirect.indexOf("/"))
    if (_redirect) history.replace(_redirect)
  }
  return { isAuthenticated: true }
}

/**
 * To disable and enable back automatic OIDC, use autoOidc in the URL.
 */
export async function authenticate(options) {
  try {
    // In case we have a valid token in the local storage we don't trigger the OIDC login.
    // This is as safe as for users not using OIDC anyway.
    // The purpose of that is, when using automatic OIDC, to avoid signing in OIDC users each time they open a new tab
    // because when they open them quickly the OIDC provider may block them due to throttling.
    const authToken = getAuthorizationToken()
    if (authToken) {
      await preloadUserConfiguration()
      // if successful, the normal initialization circuit of the app will continue
      return
    }
  } catch (error) {
    // ignore error and continue normally
    resetHeaderAuthorization()
  }

  const { manualTrigger, history } = options || {}
  const { autoOidc } = searchParamToObject(window.location.search)

  if (manualTrigger) {
    localStorage.removeItem(localStorageKeys.OIDC.AUTO)
  } else {
    if (["1", "true"].includes(autoOidc)) {
      localStorage.removeItem(localStorageKeys.OIDC.AUTO)
    } else if (["0", "false"].includes(autoOidc) || localStorage.getItem(localStorageKeys.OIDC.AUTO)) {
      // We need to store and check the local storage for the autoOidc flag to maintain it even when the app is reload in this mode.
      // Note that localStorage provides strings only, so what we are really checking here is the existence of the string, not its value.
      localStorage.setItem(localStorageKeys.OIDC.AUTO, false)
      return
    }
  }

  try {
    const { isAuthenticated, shouldRedirectToOidcProvider } = await checkAuthentication({ history })
    if (isAuthenticated) console.log(`User is authenticated with OIDC`)
    if (shouldRedirectToOidcProvider) {
      const { isRedirectingToOidcProvider } = (await redirectToProviderLoginPage()) || {}
      return { isRedirectingToOidcProvider }
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message
    // This is a feature so that the script oidc-login can prevent logging in under certain conditions
    state.loginError = error.response.status === 403 && errorMessage ? errorMessage : null
    console.error("Cannot authenticate user with OIDC")
    await authLogout()
  }
}

export function cleanup() {
  for (const key in localStorageKeys.OIDC) {
    localStorage.removeItem(localStorageKeys.OIDC[key])
  }
}

export async function logout() {
  const idToken = localStorage.getItem(localStorageKeys.OIDC.ID_TOKEN)
  const logoutUrl = localStorage.getItem(localStorageKeys.OIDC.LOGOUT_URL)
  const redirectUri = localStorage.getItem(localStorageKeys.OIDC.REDIRECT_URI)
  cleanup()

  if (logoutUrl) {
    let href = logoutUrl
    if (redirectUri) href += `?post_logout_redirect_uri=${redirectUri}`
    if (idToken) href += (redirectUri ? "&" : "?") + `id_token_hint=${idToken}`
    window.location.href = href
    return new Promise(resolve => {
      // the redirection is not instantaneous so we need a delay
      // to avoid callers of this function to have to delay their work if they rely on the outcome of this call
      setTimeout(() => resolve(), 400)
    })
  }
}

export function getLoginError() {
  return state.loginError
}

export async function refreshAccessToken(options = {}) {
  if (state.isRefreshingToken) return

  const user = getUser()

  // At init these variables are mounted in the user by the back-end.
  if (!state.refreshTokenExpiresIn && user?.ssoRefreshTokenExpiresIn) {
    state.refreshTokenExpiresIn = user.ssoRefreshTokenExpiresIn
  }
  if (!state.refreshTokenIat && user?.ssoRefreshTokenIat) {
    state.refreshTokenIat = user.ssoRefreshTokenIat
  }
  // After init, they are provided by the auth check API when users reload the app.
  if (options.ssoRefreshTokenExpiresIn) {
    state.refreshTokenExpiresIn = options.ssoRefreshTokenExpiresIn
  }
  if (options.ssoRefreshTokenIat) {
    state.refreshTokenIat = options.ssoRefreshTokenIat
  }

  if (!state.refreshTokenExpiresIn || !state.refreshTokenIat) return

  const refreshTokenThreshold = state.refreshTokenExpiresIn < refreshTokenLifetimeThreshold ? refreshTokenShortThreshold : refreshTokenLongThreshold

  if (state.refreshTokenExpiresIn < refreshTokenThreshold) return

  const timestampInSeconds = Math.round(Date.now() / 1000)
  const remainingSeconds = state.refreshTokenIat + state.refreshTokenExpiresIn - timestampInSeconds

  if (debug) {
    console.log(`OIDC refresh token has ${remainingSeconds} remaining seconds, will be refreshed when less than ${refreshTokenThreshold}`)
  }

  if (remainingSeconds > 0 && remainingSeconds <= refreshTokenThreshold) {
    const oidcId = localStorage.getItem(localStorageKeys.OIDC.ID)
    state.isRefreshingToken = true
    try {
      console.log("Attempting to refreshing the access token")
      const { data } = await axios.post(`/api/core/oidc/${oidcId}/access-token`, { grantType: "refresh_token" })
      // data.accessToken: the new token is also returned in the headers, which are intercepted in our service axios.js
      state.refreshTokenIat = data.ssoRefreshTokenIat
      state.refreshTokenExpiresIn = data.ssoRefreshTokenExpiresIn
    } catch (error) {
      console.log(error)
    } finally {
      state.isRefreshingToken = false
    }
  }
}
