import axios from "axios"
import { arrayIntersects, coreTenants, lowerCaseFirstLetter } from "basikon-common-utils"
import get from "lodash.get"

import { userHasLoggedIn } from "@/_services/authentication"
import { getUriAndCacheResponse } from "@/_services/cache"
import { setConsoleMessages } from "@/_services/console"
import firebaseMessaging from "@/_services/firebase-messaging"
import { setLocale } from "@/_services/localization"
import { offlineExecuteActions } from "@/_services/offlineService"
import { refreshAccessToken } from "@/_services/oidc"
import { applyUserConfigurationStyles, initUiThemingUserPreferences, removeUserConfigurationStyles, userPreferencesTypes } from "@/_services/theming"
import { customEvents, fileToBase64, getEntities, htmlTag, inferDataUriFromBase64, initOrganizationsWithAscendants } from "@/_services/utils"

const state = {
  menus: null,
  userConfiguration: null,
  loadingDate: null,
  pinnedItems: [],
  initialUserContextName: null,
  entityTimeZone: null,
}

function getPinnedEntities({ entityName, registration }) {
  return (state.pinnedItems || []).filter(pinnedEntity => {
    const isEntityNameMatch = pinnedEntity.value?.entityName === entityName
    const isRegistrationMatch = registration ? pinnedEntity.value?.registration === registration : true
    return isEntityNameMatch && isRegistrationMatch
  })
}

async function fetchPinnedEntities({ entityName, queryParams }) {
  const pinnedEntitiesRegistrationString = getPinnedEntities({ entityName })
    .map(pinnedEntity => pinnedEntity.value?.registration)
    .join(",")
  if (!pinnedEntitiesRegistrationString) return []
  const { data: pinnedItems } = await getEntities(entityName, { ...queryParams, registration: pinnedEntitiesRegistrationString })
  return pinnedItems
}

async function pinEntity({ entityName, registration }) {
  const { data } = await axios.post(`/api/core/users/${getUsername()}/preferences`, {
    type: userPreferencesTypes.PINNED_ITEM,
    value: {
      entityName,
      registration,
    },
  })

  state.pinnedItems.push(data)
  return data
}

async function unpinEntity({ entityName, registration }) {
  const pinnedEntityIndex = state.pinnedItems.findIndex(pinnedEntity => {
    return pinnedEntity.value?.entityName === entityName && pinnedEntity.value?.registration === registration
  })
  if (pinnedEntityIndex > -1) {
    await axios.delete(`/api/core/users/${getUsername()}/preferences/${state.pinnedItems[pinnedEntityIndex]._id}`)
    state.pinnedItems.splice(pinnedEntityIndex, 1)
  }
}

async function addOrUpdateUserPreference({ _id, groupId, type, value }) {
  const { data: savedUserPreference } = await axios.post(`/api/core/users/${getUsername()}/preferences`, {
    _id,
    groupId,
    type,
    value,
  })

  if (_id) {
    for (let i = 0; i < state.userConfiguration.userPreferences?.length; i++) {
      const userPreference = state.userConfiguration.userPreferences[i]
      if (userPreference._id === _id) {
        state.userConfiguration.userPreferences[i] = savedUserPreference
        break
      }
    }
  } else {
    state.userConfiguration.userPreferences ??= []
    state.userConfiguration.userPreferences.push(savedUserPreference)
  }

  return savedUserPreference
}

async function deleteUserPreference({ _id }) {
  if (!_id) return

  await axios.delete(`/api/core/users/${getUsername()}/preferences/${_id}`)
  const userPreferenceIndex = state.userConfiguration.userPreferences?.findIndex(userPreference => userPreference._id === _id)
  if (userPreferenceIndex > -1) {
    state.userConfiguration.userPreferences.splice(userPreferenceIndex, 1)
  }
}

/**
 * We need await here to make sure there is no styles flickering.
 */
async function removeAndApplyUserConfigurationStyles({ implementation }) {
  removeUserConfigurationStyles({ resetPageTitle: false })
  await applyUserConfigurationStyles({ implementation })
}

async function preloadUserConfiguration() {
  state.userConfiguration = state.userConfiguration || (await axios.get("/api/script/runs/user-configuration")).data
}

async function loadUserConfiguration({
  history,
  // there is init possible, either from the LoginPage, or if having a valid token in local storage, from PagesLayout
  isInit,
  keepIfLoaded,
  userLocale: argUserLocale,
  loadFirebase,
  loadOrganizationsWithAscendants,
  initConfigurationStyles,
  executeOfflineActions,
} = {}) {
  try {
    // This is specific for the dev env hot-reload.
    // Without that, when hot-reload happens, the styles are lost.
    if (keepIfLoaded && state.userConfiguration) {
      if (initConfigurationStyles) {
        await removeAndApplyUserConfigurationStyles({ implementation: state.userConfiguration.user?.tenant })
      }
      return state.userConfiguration
    }

    if (isInit && state.initialUserContextName) {
      try {
        // The returned token will be intercepted by axios
        await axios.get(`/api/core/user-switch-context?contextName=${state.initialUserContextName}`)
        state.initialUserContextName = undefined
      } catch (error) {
        // This is optional so we don't fallback when there is an error
      }
    }

    const canUsePreloadedUserConf = isInit && state.userConfiguration
    const userConfiguration = canUsePreloadedUserConf ? state.userConfiguration : (await axios.get("/api/script/runs/user-configuration")).data
    const { user } = userConfiguration || {}
    const { tenant, locale, ssoRefreshTokenExpiresIn, ssoRefreshTokenIat } = user || {}
    const userLocale = argUserLocale || locale

    if (isInit) {
      try {
        await refreshAccessToken({ ssoRefreshTokenExpiresIn, ssoRefreshTokenIat })
      } catch (error) {
        // This is optional so we don't fallback when there is an error
      }
    }

    userHasLoggedIn({ history, userLocale })

    const promises = []
    if (userLocale) promises.push(setLocale({ locale: userLocale }))
    if (loadOrganizationsWithAscendants) promises.push(initOrganizationsWithAscendants())
    await Promise.all(promises)

    state.userConfiguration = userConfiguration
    state.menus = userConfiguration.menus?.filter(it => !it.profiles || arrayIntersects(it.profiles, userConfiguration.user?.profiles))
    state.loadingDate = new Date()
    state.pinnedItems = userConfiguration.userPreferences?.filter(({ type }) => type === userPreferencesTypes.PINNED_ITEM)

    // Warning: some functions below require the user configuration to be set in the state so make sure to write them after that.
    // Also, put functions that do need to awaited first, and make sure to catch errors in them directly.
    setConsoleMessages(userConfiguration)
    initUiThemingUserPreferences(userConfiguration)
    getUserPhoto() // no await to accelerate loading

    if (loadFirebase && userConfiguration?.options?.firebase) {
      firebaseMessaging() // no await to accelerate loading
    }

    if (executeOfflineActions) {
      // no await to accelerate loading
      // check if any pending action should be synched
      offlineExecuteActions()
    }

    if (initConfigurationStyles) {
      await removeAndApplyUserConfigurationStyles({ implementation: tenant })
    }
  } catch (error) {
    if (error.response?.status === 401) throw error

    // The user is still logged in when the error is not 401.
    // This allows continuing the application in case of error,
    // notably in the user-configuration script where configurator might introduce them.
    userHasLoggedIn({ history })

    // never show an error when user-configuration does not load
    // we need this because newly created tenant have no user-configuration yet
    const { data: user } = await axios.get("/api/core/users/current")
    const userConfiguration = {
      menus: [],
      landingPage: "/documentation/getting-started/getting-started",
      user,
      options: {
        disableFirebase: true, // TODO: get rid of this by default
      },
    }
    state.userConfiguration = userConfiguration
    state.menus = userConfiguration.menus
  }
}

function isRtl() {
  return htmlTag.getAttribute("data-rtl") === "true"
}

function unloadUserConfiguration() {
  state.userConfiguration = null
}

function getUserConfiguration() {
  return state.userConfiguration
}

function getOptions(path, defaultValue) {
  const { options } = state.userConfiguration || {}
  return get(options, path, defaultValue)
}

function getConfigAtPath(path) {
  return get(state.userConfiguration || {}, path)
}

function getMenus() {
  return state.menus
}

function getAllowedRoutesSet() {
  const userMenus = (state.menus || [])
    .map(menu => (menu.menus ? menu.menus.map(m => m.path) : menu.path))
    .flat()
    .map(path => (path.includes("?") ? path.substring(0, path.indexOf("?")) : path)) // Remove ?query params

  const allowedRoutesSet = new Set(userMenus) // Remove duplicates
  allowedRoutesSet.add(getLandingPage())

  // For entity routes (ex: /contract/:id) and routes not existing in user menus (ex: /users, /batches, etc..)
  for (const route of getOptions("allowedRoutes", [])) allowedRoutesSet.add(route)

  return allowedRoutesSet
}

function getLandingPage() {
  return state.userConfiguration?.landingPage || "/"
}

function getPublicPath(tenant) {
  if (tenant) return `/imp/${tenant}`

  let publicPath = getOptions("publicPath")
  if (!publicPath) publicPath = `/imp/${getTenant()}`
  return publicPath
}

function canCheckTenantConfig() {
  return getIsAdmin() || !getUserConfiguration() || getImpersonatingTenant() || ["DEV", "UAT"].includes(getEnvironment())
}

function getEnvironment() {
  return state.userConfiguration?.environment?.environment ?? state.userConfiguration?.environment
}

function envIsProduction() {
  return state.userConfiguration?.environment?.isProduction ?? state.userConfiguration?.isProduction
}

function envIsDevelopment() {
  return state.userConfiguration?.environment?.isDevelopment ?? state.userConfiguration?.isDevelopment
}

function envIsUat() {
  return state.userConfiguration?.environment?.isUat ?? state.userConfiguration?.isUat
}

function envIsTest() {
  return state.userConfiguration?.environment?.isTest ?? state.userConfiguration?.isTest
}

function envIsStaging() {
  return state.userConfiguration?.environment?.isStaging ?? state.userConfiguration?.isStaging
}

function getScriptOverride(scriptName) {
  return state.userConfiguration?.scriptOverrides?.[scriptName]
}

function getUser() {
  return state.userConfiguration?.user
}

function getUserPermissions() {
  return state.userConfiguration?.userPermissions
}

function getUsername() {
  return state.userConfiguration?.user?.username
}

function getTenant() {
  return state.userConfiguration?.user?.tenant
}

function getImpersonatingTenant() {
  return state.userConfiguration?.user?.impersonatingTenant
}

function getProfiles() {
  return state.userConfiguration?.user?.profiles
}

function hasProfile(profile) {
  return arrayIntersects(state.userConfiguration?.user?.profiles || [], profile)
}

function getIsAdmin() {
  return state.userConfiguration?.user?.isAdmin
}

function getIsMainAdmin() {
  return state.userConfiguration?.user?.isMainAdmin
}

function getIsSuperAdmin() {
  return state.userConfiguration?.user?.isSuperAdmin
}

function getPersonRegistration() {
  return state.userConfiguration?.user?.personRegistration
}

function getPartnerRegistration() {
  return state.userConfiguration?.user?.partnerRegistration
}

function getOrgaRegistration() {
  return state.userConfiguration?.user?.orgaRegistration
}

function getTimeZone() {
  return state.entityTimeZone || state.userConfiguration?.user?.timeZone
}

function getUserPreferences() {
  return state.userConfiguration?.userPreferences || []
}

function getCollectMissingTranslations() {
  return state.userConfiguration?.collectMissingTranslations || false
}

/**
 * If the user is simple admin, main admin or super admin then returns true.
 * If the permission checked starts with read or write, this function also checks
 * for the read all and write all permission respectively.
 * This is to avoid manually having to perform these checks in addition to calling this method.
 */
function hasPermission(permissionKey, { strictCheck, allowAdmins = true } = {}) {
  if (allowAdmins && (getIsAdmin() || getIsMainAdmin() || getIsSuperAdmin())) return true
  if (!permissionKey) return

  const { userPermissions = {} } = state.userConfiguration || {}
  if (userPermissions[permissionKey] === true) return true
  if (!strictCheck) {
    return permissionKey.startsWith("read") ? userPermissions["read all"] : permissionKey.startsWith("write") ? userPermissions["write all"] : false
  }
}

function hasReadPermission(serverRoute, { allowAdmins = true } = {}) {
  if (allowAdmins && (getIsAdmin() || getIsMainAdmin() || getIsSuperAdmin())) return true
  if (!serverRoute) return

  const { userPermissions = {} } = state.userConfiguration || {}
  return userPermissions["read all"] || userPermissions[`read ${serverRoute}`] || userPermissions[`get ${serverRoute}`]
}

async function getUserPhoto(user) {
  try {
    user || getUser()
    if (user?.photo) return user.photo
    if (!state.userConfiguration?.user?._id) return

    const imageData = (await axios.get(`/api/core/users/${user.username}/photo?no404=1`, { responseType: "arraybuffer" })).data
    const photo = inferDataUriFromBase64(Buffer.from(imageData).toString("base64"))

    if (getUsername() === user.username) {
      state.userConfiguration.user.photo = photo
    }
    return photo
  } catch (error) {
    if (state.userConfiguration?.user?.photo) delete state.userConfiguration.user.photo
    // do not throw error, it is normal a user has no photo
    // throw error
  }
}

async function uploadUserPhoto(user, formData) {
  if (!state.userConfiguration.user?._id) return

  await axios.post(`/api/core/users/${user.username}/photo`, formData, { headers: { "Content-Type": "multipart/form-data" } })
  const photo = inferDataUriFromBase64(await fileToBase64(formData.get("file")))
  if (getUsername() === user.username) {
    state.userConfiguration.user.photo = photo
  }
  window.dispatchEvent(new CustomEvent(customEvents.user.photoHasChanged, { detail: { photo } }))
  return photo
}

async function deleteUserPhoto(user) {
  if (!state.userConfiguration.user?._id) return

  await axios.delete(`/api/core/users/${user.username}/photo`)
  if (getUsername() === user.username) {
    delete state.userConfiguration.user.photo
  }
  window.dispatchEvent(new CustomEvent(customEvents.user.photoHasChanged, { detail: { photo: null } }))
}

function getPageConfig(componentName) {
  const pageName = lowerCaseFirstLetter(componentName) // "AssetLotPage" => "assetLotPage"
  let pageConfig = getConfigAtPath(`clientRoutes.${pageName}`)

  // TODO: Remove me after migrating all page configs under "clientRoutes" in "user-configuration" script
  if (!pageConfig) pageConfig = getConfigAtPath(pageName)
  if (!pageConfig) pageConfig = getConfigAtPath(componentName)

  return pageConfig || {}
}

function canReadScripts() {
  return hasPermission("read /api/script/scripts", { strictCheck: true })
}

function canReadUsers() {
  return hasPermission("read /api/core/users")
}

function canReadTasks() {
  return hasPermission("read /api/core/tasks")
}

function canReadImports() {
  return hasPermission("read /api/import/imports")
}

function canWriteImports() {
  return hasPermission("write /api/import/imports")
}

function canReadBatches() {
  return hasPermission("read /api/script/batches")
}

function getLoadingDate() {
  return state.loadingDate
}

function isMasterTenant() {
  return getTenant() === coreTenants.MASTER
}

function setInitialUserContext({ initialUserContextNameBase64X2 }) {
  // the base64 thing is a (very) small security measure so that non-authorized people cannot
  // just look at links to learn what users have access to
  if (!initialUserContextNameBase64X2) return
  state.initialUserContextName = atob(atob(initialUserContextNameBase64X2))
}

async function setEntityTimeZone(entity) {
  const { orgaRegistration, timeZone } = entity
  state.entityTimeZone =
    timeZone || (orgaRegistration && (await getUriAndCacheResponse(`/api/person/persons/${orgaRegistration}?projection=timeZone`)).timeZone)
}

function cleanEntityTimeZone() {
  state.entityTimeZone = null
}

export {
  addOrUpdateUserPreference,
  canCheckTenantConfig,
  canReadBatches,
  canReadImports,
  canReadScripts,
  canReadTasks,
  canReadUsers,
  canWriteImports,
  cleanEntityTimeZone,
  deleteUserPhoto,
  deleteUserPreference,
  envIsDevelopment,
  envIsProduction,
  envIsStaging,
  envIsTest,
  envIsUat,
  fetchPinnedEntities,
  getAllowedRoutesSet,
  getCollectMissingTranslations,
  getConfigAtPath,
  getEnvironment,
  getImpersonatingTenant,
  getIsAdmin,
  getIsMainAdmin,
  getIsSuperAdmin,
  getLandingPage,
  getLoadingDate,
  getMenus,
  getOptions,
  getOrgaRegistration,
  getPageConfig,
  getPartnerRegistration,
  getPersonRegistration,
  getPinnedEntities,
  getProfiles,
  getPublicPath,
  getScriptOverride,
  getTenant,
  getTimeZone,
  getUser,
  getUserConfiguration,
  getUsername,
  getUserPermissions,
  getUserPhoto,
  getUserPreferences,
  hasPermission,
  hasProfile,
  hasReadPermission,
  isMasterTenant,
  isRtl,
  loadUserConfiguration,
  pinEntity,
  preloadUserConfiguration,
  setEntityTimeZone,
  setInitialUserContext,
  unloadUserConfiguration,
  unpinEntity,
  uploadUserPhoto,
}
